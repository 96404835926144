.search-panel {
  display: flex;
  flex-direction: column;
  margin: 20px;
  margin-left: 30%;
}

.search-panel-options {
  display: flex;
}

.search-panel-options > div {
  margin-right: 20px;
}

.search-results-table-of-contents {
  margin: 0px 20px;
}

.search-results-table-of-contents table {
  margin: 30px 0px;
}

.search-results-table-of-contents table td {
  min-width: 40px;
}

.search-results-sort-panel > * {
  margin: 0px 5px;
}
