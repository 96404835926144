.tabbed-list-li {
  background: var(--fnx-white);
  border-radius: 1em;
  box-shadow:
    0.25rem 0.25rem 0.6rem rgba(0, 0, 0, 0.5),
    0 0.5rem 1.125rem rgba(75, 0, 0, 0.05);
  margin-bottom: 30px;
  padding: 20px 30px;
  cursor: pointer;
  list-style: none;
}

.tabbed-list-li.selected {
  background-color: var(--fnx-total-green);
  color: var(--fnx-white);
}

.thin-list {
  padding: 10px 20px;
}

.very-thin-list {
  padding: 10px 20px;
  min-height: 1.5rem;
}

.notself {
  background: #dedede;
  cursor: default;
}

.notself-primary {
  background: var(--primary-extra-light);
  cursor: default;
}

.tabbed-list-li-div {
  display: flex;
  justify-content: space-between;
  margin: 5px 0px;
}

.tabbed-list-li-div .overflow-protect {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 200px;
  min-width: 200px;
}

.tabbed-list-li-div .bold-text {
  font-weight: bold;
}

.tabbed-list-li-cell {
  margin-top: 5px;
  font-size: small;
}

/* use only for ol */
.noDiscList {
  list-style: none;
  padding-inline-start: 0px;
}
