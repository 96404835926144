.preferences-container {
  display: flex;
  margin-top: 5%;
}

.preferences-left-pane {
  width: 60%;
  margin-right: 20px;
  min-height: 500px;
}

.preferences-right-pane {
  min-width: 40%;
  padding: 20px;
  border: 2px solid darkgrey;
  background-color: var(--fnx-light-grey3);
  border-radius: 20px;
}

.preferences-row-title {
  font-weight: bold;
  font-size: large;
  display: flex;
  align-items: center;
}

.preferences-row-desc {
  font-size: smaller;
}

.preferences-row {
  margin: 40px 0px;
}
.preferences-row-title-heading {
  width: 75%;
}
