#slide-control .control-button {
  min-width: 50px;
}
.launch-tagline {
  text-align: center;
  margin: 2% 0% 0% 0%;
  font-size: larger;
  font-weight: bold;
}

.slide-component {
  display: flex;
  padding: 40px 0px;
}

.slide-component div {
  display: flex;
  flex-direction: column;
  font-size: large;
}

.slide-component .section-title {
  color: blue;
}

.slide-component .section a {
  font-weight: bold;
  font-size: x-large;
  margin: 10px 0px;
}

.slide-component .section p {
  font-size: large;
}

.mobile-ad-tile-orange {
  background: antiquewhite;
  border-radius: 1em;
  box-shadow:
    0.25rem 0.25rem 0.6rem #c6610080,
    0 0.5rem 1.125rem rgba(75, 0, 0, 0.05);
  padding: 1rem 1rem 1rem 1rem;
  position: relative;
  margin: 40px 20px;
  overflow-y: auto;
  scroll-behavior: auto;
}

.mobile-ad-tile-blue {
  background: aliceblue;
  border-radius: 1em;
  box-shadow:
    0.25rem 0.25rem 0.6rem #0176a680,
    0 0.5rem 1.125rem rgba(75, 0, 0, 0.05);
  padding: 1rem 1rem 1rem 1rem;
  position: relative;
  margin: 40px 20px;
  overflow-y: auto;
  scroll-behavior: auto;
}

.mobile-ad-tile-green {
  background: #5ef0ab;
  border-radius: 1em;
  box-shadow:
    0.25rem 0.25rem 0.6rem #08540f80,
    0 0.5rem 1.125rem rgba(75, 0, 0, 0.05);
  padding: 1rem 1rem 1rem 1rem;
  position: relative;
  margin: 40px 20px;
  overflow-y: auto;
  scroll-behavior: auto;
}
