.home-profileSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0px 0px;
}

.home-toprow {
  display: flex;
  justify-content: space-between;
}

.home-chart-wrapper {
  display: flex;
  min-height: 300px;
}

.otherUserExpense {
  background: #e0dede;
  border-radius: 1em;
  box-shadow:
    0.25rem 0.25rem 0.6rem rgba(0, 0, 0, 0.5),
    0 0.5rem 1.125rem rgba(75, 0, 0, 0.05);
  margin-top: 1rem;
  min-height: 3rem;
  padding: 1rem 1rem 1rem 3rem;
  position: relative;
}

.notifications {
  margin: 0px 20px;
  cursor: pointer;
}
