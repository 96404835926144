.table-footer {
  margin-top: 3%;
}

table.exhutTable {
  width: 100%;
  margin: "3% 0%";
  text-align: left;
}
table.exhutTable td {
  border: 1px solid #000000;
  padding: 5px 4px;
}
table.exhutTable th {
  border: 3px solid #c368fc;
  padding: 5px 4px;
}
table.exhutTable tbody td {
  font-size: 15px;
  color: black;
}
/*table.exhutTable thead {
  background: -moz-linear-gradient(top, #4b4b4b 0%, #272727 66%, #0F0F0F 100%);
  background: -webkit-linear-gradient(top, #4b4b4b 0%, #272727 66%, #0F0F0F 100%);
  background: linear-gradient(to bottom, #4b4b4b 0%, #272727 66%, #0F0F0F 100%);
}*/
table.exhutTable thead th {
  background: #7f1bd0;
  font-size: 15px;
  font-weight: bold;
  color: #faf7f3;
  text-align: left;
}

table.generalTable {
  width: 100%;
  text-align: left;
  -webkit-border-vertical-spacing: 1.5em;
}

#savingsTable {
  margin: 30px 0px;
}
