html {
  scroll-behavior: smooth;
}

body {
  font-family: "Source Sans Pro", sans-serif;
  font-family: Questrial;
}

:root {
  --primary: #7f1bd0;
  --primary-light: #be83ee;
  --primary-extra-light: #ddb3ff;
  --fnx-black: #020202;
  --fnx-dark-grey: #7a7a7a;
  --fnx-green: #02a802;
  --fnx-light-green: #65f34b;
  --fnx-light-grey0: #969696;
  --fnx-light-grey3: #e2e2e2;
  --fnx-light-grey5: #ececec;
  --fnx-orange: #e4a701;
  --fnx-red: #ff0000;
  --fnx-light-red: #ff7171;
  --fnx-total-green: #06b191;
  --fnx-white: #ffffff;
  --fnx-blue: #2196f3;
  --fnx-dark-blue: blue;
  --fnx-ramar-blue: #66baff;
  --fnx-yellow: #eeff00;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  color: white;
  font-size: medium;
  margin: 5px;
  width: 100%;
  text-align: center;
  margin: 0px 0px 0px 0px;
  padding: 10px 0px;
  background-color: var(--primary);
}

.footer-copyright {
  font-weight: bold;
}

.splitleft {
  left: 0;
  width: 20%;
  background: var(--primary);
  height: 100%;
  position: fixed;
  top: 0;
  overflow-y: auto;
}

.splitright {
  right: 0;
  height: 100%;
  top: 0;
  position: fixed;
  width: 80%;
  overflow-y: auto;
}

.topRightFixed {
  position: absolute;
  top: 5%;
  right: 3%;
  display: flex;
}

.splitright .container {
  margin: 0px 30px 0px 20px;
}

.title {
  text-align: center;
  font-size: xx-large;
  margin: 3% 0% 2% 0%;
  font-weight: bold;
}

/* Remove the above .title after we start using this. This is usually used for title with extra
top margin for status message. */
.title-extra {
  text-align: center;
  font-size: xx-large;
  margin: 5% 0% 2% 0%;
  font-weight: bold;
}

.smalltitle {
  text-align: center;
  font-size: x-large;
  margin: 3% 0% 2% 0%;
  font-weight: bold;
}

.subtitle {
  text-align: center;
  font-size: larger;
  margin: 3% 0% 3% 0%;
  font-weight: bold;
}

.total {
  font-size: 2vw;
  font-weight: bold;
  text-align: center;
  color: var(--fnx-total-green);
  flex: 1;
}

/* The total font size under tilesmall should be 1.5 */
.tile-small .total {
  font-size: 1.5vw;
}

input[type="button"].fnxGreen {
  margin: 0px 10px;
}

input[type="text"],
input[type="password"] {
  width: 200px;
  padding: 5px;
  border-radius: 6px;
}

input.borderless {
  border: 0px;
  border-bottom: 2px solid var(--fnx-black);
  border-radius: 0px;
}

input[type="checkbox"] {
  width: 15px;
  height: 15px;
}

.largerText {
  font-size: 20px;
}

.largeText {
  font-size: 18px;
}

.mediumText {
  font-size: 12px;
}

.smallText {
  font-size: 10px;
}

.boldText {
  font-weight: bold;
}

.errorMsg {
  color: var(--fnx-red);
}

.successMsg {
  color: var(--fnx-green);
}

.warnMsg {
  color: var(--fnx-orange);
}

.infoMsg {
  color: var(--fnx-dark-grey);
}

.blueMsg {
  color: var(--fnx-dark-blue);
}

/* Use this within the div of status msg */
.statusMsg {
  justify-content: center;
  margin: 10px 0px;
  display: flex;
  min-height: 1em;
}

.greeting {
  margin-left: 2em;
  font-size: 2em;
  margin-top: 3em;
  -webkit-animation: fadein 3.5s;
  animation: fadein 3.5s;
}

.greeting h1 {
  color: blue;
}

.greeting-body {
  margin-left: 2em;
  font-size: 1.3em;
  line-height: 2em;
}

.tableHeader {
  font-weight: bold;
}

/* Use this for multiple links which will be space-between */
.divHeaderSpread {
  margin: 0px 0px 40px 0px;
  display: flex;
  justify-content: space-between;
}

.divHeader {
  margin: 0px 0px 40px 0px;
  display: flex;
  justify-content: center;
}

.hyperlink {
  color: darkblue;
  margin-right: 5px;
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 2px;
}

.hyperlink-no-color {
  margin-right: 5px;
  cursor: pointer;
  text-decoration: underline;
}

.hyperlink-green {
  color: var(--fnx-total-green);
  margin: 0px 10px;
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 2px;
}

.margin-center {
  margin: 0px 44px 40px 44px;
}

sup {
  color: red;
  font-weight: bold;
}

hr.shadowed {
  color: var(--fnx-dark-grey);
  height: 0px;
  background-color: var(--fnx-dark-grey);
  box-shadow:
    0.25rem 0.25rem 0.2rem rgba(0, 0, 0, 0.5),
    0 0.5rem 1.125rem rgba(75, 0, 0, 0.05);
  margin: 30px 0px;
}

.iconSize {
  width: 20px;
  height: 20px;
}

.iconSizeSmall {
  width: 15px;
  height: 15px;
}

.divFlex {
  display: flex;
}

.divCenter {
  display: flex;
  text-align: center;
  justify-content: center;
}

.divCenterAlignItemsOnly {
  display: flex;
  text-align: center;
  align-items: center;
}

.divCenterTextAlign {
  display: flex;
  text-align: center;
}

.divCenterAlign {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.divSpread {
  display: flex;
  justify-content: space-between;
}

.divSpreadEven {
  display: flex;
  justify-content: space-evenly;
}

.divSpreadRight {
  display: flex;
  justify-content: flex-end;
}

.divColumn {
  display: flex;
  flex-direction: column;
}

.div-column-2 {
  display: flex;
  flex-direction: column;
}

.divColumn > * {
  margin: 30px 0px 0px 0px;
}

.divColumnSpread {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.divLeftAlign {
  align-items: flex-start;
}

.divRight {
  display: flex;
  justify-content: flex-end;
}

.divRightAlign {
  align-items: flex-end;
}

/* Animations to fade the snackbar in and out */
@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeinstatus {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.status-msg-show {
  position: fixed;
  top: 0px;
  left: 50%;
  padding: 10px;
  width: 400px;
  animation-name: fadeinstatus;
  animation-duration: 1s;
  opacity: 0;
  height: 1em;
  color: var(--fnx-white);
  text-align: center;
  font-size: smaller;
  border-radius: 0px 0px 10px 10px;
}

.status-msg-hide {
  visibility: hidden;
  position: fixed;
  top: 0px;
}

.clickable {
  border: 0px solid var(--fnx-black);
  border-radius: 10px;
  padding: 10px;
  background-color: var(--fnx-light-grey5);
  margin: 0px 10px;
  cursor: pointer;
}

.hidden {
  visibility: hidden;
}

.topMargin5P {
  margin: 5% 0%;
}

.topMargin2P {
  margin: 2% 0%;
}

.topMargin1P {
  margin: 1% 0% 2% 0%;
}

.topMargin0P {
  margin: 0% 0% 2% 0%;
}

#chart-selected-component strong {
  margin: 0px 5px;
}

.animate-right {
  position: relative;
  animation: rightanimate 0.4s;
}

@keyframes rightanimate {
  from {
    right: -300px;
    opacity: 0;
  }
  to {
    right: 0;
    opacity: 1;
  }
}

.animate-zoom {
  animation: animatezoom 0.6s;
}

@keyframes animatezoom {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

.error-background {
  background-color: #ffa0a0;
}

.p-0 {
  padding: 0px;
}
.p-8 {
  padding: 8px;
}

.p-10 {
  padding: 10px;
}

.p-12 {
  padding: 12px;
}

.p-16 {
  padding: 16px;
}

.p-20 {
  padding: 20px;
}

.p-32 {
  padding: 32px;
}

.p-40 {
  padding: 40px;
}

.p-60 {
  padding: 60px;
}

.px-20 {
  padding: 0px 20px;
}

.px-30 {
  padding: 0px 30px;
}

.px-40 {
  padding: 0px 40px;
}

.py-8 {
  padding: 8px 0px;
}

.py-12 {
  padding: 12px 0px;
}

.py-40 {
  padding: 40px 0px;
}

.pt-0 {
  padding-top: 0;
}

.w-80 {
  width: 80%;
}
.w-40 {
  width: 40%;
}

.w-50 {
  width: 50%;
}
.w-60 {
  width: 60%;
}
.w-90 {
  width: 90%;
}

.w-100 {
  width: 100%;
}

.vh-100 {
  height: 100vh;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-40 {
  margin-top: 40px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mx-5 {
  margin: 0px 5px;
}

.mx-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.mx-20 {
  margin-left: 20px;
  margin-right: 20px;
}

.mx-40 {
  margin-right: 40px;
  margin-left: 40px;
}
.mx-60 {
  margin-right: 60px;
  margin-left: 60px;
}

.mx-80 {
  margin-right: 80px;
  margin-left: 80px;
}

.mx-100 {
  margin-left: 100px;
  margin-right: 100px;
}

.mt-10 {
  margin-top: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}
.mb-40 {
  margin-bottom: 40px;
}

.my-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.my-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.my-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.my-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.my-60 {
  margin: 60px 0px;
}

.my-80 {
  margin: 80px 0px;
}

ul.disc {
  list-style: disc;
}
ul.none {
  list-style: none;
}

ul.check li:before {
  content: "✓";
  margin-right: 10px;
}

.blue-button {
  /* Frame 5 */

  /* Auto layout */
  display: flex;

  justify-content: center;
  align-items: center;
  padding: 12px 24px;

  width: 80px;
  height: 24px;
  color: white;
  font-weight: bold;
  text-decoration: none;

  background: #14c8c8;
  border-radius: 70px;
}

.divAlignItemsOnly {
  display: flex;
  align-items: center;
}
