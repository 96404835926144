.savings-statusMsg {
  margin: 3% 0%;
  height: 1em;
}

.savingsBody .savings-pagel-left {
  padding: 20px 10px;
  width: 50%;
}

.savingsBody .savings-pagel-right {
  width: 50%;
  padding: 20px 10px;
}

.savings-row-container {
  background-color: var(--fnx-light-grey3);
  border: 1px solid var(--fnx-light-grey0);
  padding: 20px 20px;
  border-radius: 10px;
}

.estimate-savings-panel {
  margin: 18px 0px;
  min-height: 80px;
  border: 1px solid var(--fnx-light-grey0);
  border-radius: 10px;
  padding: 15px;
  background-color: var(--fnx-light-grey3);
  display: flex;
  justify-content: space-between;
}

.estimate-savings-pane-no-data {
  display: flex;
  margin: 5%;
  margin-top: 30px;
  background-color: #0083c1;
  padding: 10px;
  border-radius: 20px;
  color: white;
  font-weight: bold;
}

.estimate-savings-pane-no-data a {
  margin: 0px 5px;
  color: var(--fnx-yellow);
}

.estimate-savings-panel-section-left {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.estimate-savings-panel-section-right {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  float: right;
  padding: 10px;
  border-radius: 20px;
  background-color: var(--primary);
  color: var(--fnx-white);
}

.estimate-savings-panel-section-right div {
  display: flex;
  justify-content: space-between;
}

.savingsBody .section {
  margin: 3%;
}

.savingsBody .section .subsection {
  margin-right: 5px;
  padding: 5px;
}

#savings-estimated-by-income {
  margin-left: 5px;
}

#showCurrencyConvPanel {
  display: flex;
  justify-content: space-between;
}

.savings-data-panel {
  margin-top: 40px;
  background-color: var(--fnx-light-grey3);
  padding: 15px 25px;
  border-color: var(--fnx-light-grey5);
  border-radius: 10px;
}
