.mobile-links {
  display: flex;
  margin-top: 1%;
  min-height: 100px;
  margin-left: 17%;
}

.mobile-links div {
  height: 100px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin: 0px 10px;
}
