.savings-dialog-content {
  height: 70%;
  margin-top: 5%;
}

.savings-dialog-content-body {
  margin: 1em 0em 2em 0em;
  text-align: center;
}

.savings-dialog-content-body #shared-users-panel {
  width: 220px;
  height: 100px;
  overflow-y: scroll;
  background-color: white;
  white-space: nowrap;
  overflow-x: scroll;
}

.savings-dialog-content-body #shared-users-panel ul {
  padding: 0px;
  list-style-type: none;
}

.savings-dialog-content-body #shared-users-panel ul li {
  text-align: left;
  margin-bottom: 10px;
  margin-left: 20px;
}

.savings-dialog-content-body #shared-users-panel ul li input[type="checkbox"] {
  margin-right: 20px;
}
