.expense-ai-data-section {
  margin-top: 80px;
  display: flex;
}

.expense-ai-data-section-tile {
  min-width: 200px;
  min-height: 50px;
  padding: 1rem;
  display: flex;
  flex-direction: row;
}

.expense-ai-data-section-tile-header {
  margin: 5px;
  min-width: 120px;
}

.expense-ai-data-section-tile-body {
  margin: 5px;
  min-width: 220px;
}

.expense-ai-data-section-tile-img img {
  width: 30px;
}

.expense-ai-data-section-tile-body input {
  border: 0px;
  border-bottom: 1px solid var(--primary);
  border-radius: 0px;
}

.expense-ai-data-section-tile-header span {
  color: var(--primary);
  font-weight: bold;
}

.expense-ai-data-section-icon {
  width: 50%;
  display: flex;
  justify-content: center;
}

.expense-ai-data-section-icon img {
  width: 300px;
  opacity: 0.5;
}

.expense-ai-data-section-details {
  width: 50%;
}

.expense-ai-relatedexpense-banner {
  background-color: #ff7b7b;
  color: white;
  border-radius: 10px;
  padding: 10px;
  -webkit-animation: fadein 3.5s;
  animation: fadein 3.5s;
}
