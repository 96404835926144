.trips-row {
  display: flex;
  margin-left: 10%;
  justify-content: space-between;
  text-align: center;
  margin: 20px 0px 20px 0px;
  padding: 0px 20px;
}

.trips-row span {
  width: 125px;
  text-align: left;
}

.trips-footer {
  margin: 30px 10px;
  justify-content: space-evenly;
  display: flex;
}

.trip-page {
  margin-top: 2%;
  display: flex;
  justify-content: space-between;
}

.trip-page-left-panel {
  width: 49%;
}

.add-trip-panel {
  width: 40%;
}

.add-trip-panel-body {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  border: 1px solid;
}

.trip-page-right-panel {
  width: 50%;
}

.trip-page-right-panel ol {
  list-style: none;
  padding: 0px;
  margin-top: 40px;
}

.trip-page-expense-row {
  display: flex;
  width: 100%;
}

.trip-page-expense-row div {
  display: flex;
  align-items: center;
}

#update-trip-panel input[type="text"] {
  text-align: center;
}

.trip-name {
  color: var(--fnx-ligjt-grey);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
}
