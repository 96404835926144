.snackbar {
  background: #ffc72d;
  border-radius: 1em;
  box-shadow:
    0.25rem 0.25rem 0.6rem rgba(0, 0, 0, 0.5),
    0 0.5rem 1.125rem rgba(75, 0, 0, 0.05);
  max-width: 200px;
  padding: 10px 10px;
  right: 5%;
  top: 75px;
  cursor: pointer;
  color: var(--fnx-white);
  list-style: none;
  font-size: 12px;
  font-weight: bold;
  animation: fadeinstatus 2s ease-in;
  margin: 5px 0px;
  visibility: visible;
}

.closesnack {
  color: var(--fnx-white);
  float: right;
  font-size: 15px;
}

.snackbar > div {
  margin: 0px 5px;
  display: flex;
  align-items: center;
}
