.fixedSelect {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

select {
  padding: 5px;
  border-radius: 5px;
  border: 2px solid black;
}

.filter-select {
  background-color: blueviolet;
  color: white;
  border: 1px solid black;
}

select:disabled {
  background: lightgray;
}
