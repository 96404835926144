.home-page-expense-list-body {
  margin: 0% 5%;
}

.expense-list-right-comments {
  text-overflow: ellipsis;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
}

#expense-list-filter {
  width: 30%;
}

.expense-list-filter-section {
  margin-top: 10%;
  min-height: 20px;
}

.expense-list-filter-section span {
  font-size: medium;
  font-weight: bold;
}

.expense-list-filter-section-body {
  margin-top: 5%;
}

#expenses-list {
  width: 70%;
  height: 800px;
}

#expenses-list-container {
  display: flex;
  justify-content: space-between;
  min-height: 90px;
}

#expense-list-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--fnx-dark-grey);
  width: 30%;
}

#expense-list-middle {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.expense-list-right-amount {
  font-weight: bold;
  font-size: larger;
}

.expense-list-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px 10px 0px 10px;
  text-align: right;
  width: 40%;
}
