.analysis-tile {
  background: white;
  border-radius: 1em;
  box-shadow:
    0.25rem 0.25rem 0.6rem rgba(0, 0, 0, 0.5),
    0 0.5rem 1.125rem rgba(75, 0, 0, 0.05);
  min-height: 400px;
  max-height: 400px;
  padding: 1rem 1rem 1rem 1rem;
  position: relative;
  max-width: 25%;
  min-width: 25%;
  margin: 40px 20px;
  overflow-y: auto;
  scroll-behavior: auto;
}

.tile-small {
  min-height: 150px;
  max-height: 150px;
  min-width: 200px;
  max-width: 250px;
  margin: 0px;
  overflow: hidden;
}

.tile-body {
  display: flex;
  justify-content: center;
}

.tile-body-spread {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.tile-body-spread > div {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 12px;
}

.tile-body-spread > div > * {
  margin-top: 5px;
}

.analysis-tile-heading {
  font-weight: bold;
  text-align: center;
  padding: 10px;
  color: white;
  border-radius: 5px;
  background-color: var(--primary);
}

.analysis-tile-subheading {
  font-weight: bold;
  text-align: center;
  margin: 20px 10px;
  min-height: 40px;
}

.analysis-tile-body table {
  border-spacing: 10px;
  width: 100%;
}

.analysis-tile-body table thead {
  text-align: left;
}

.analysis-tile-body table tbody tr {
  text-align: left;
  font-size: small;
}

.analysis-tile-body table th {
  padding: 10px 0px;
}

.analysis-tile-body table td,
.analysis-tile-body table th {
  max-width: 150px;
  min-width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
