.analysis-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.analysis-title .search {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 10%;
}

.analysis-tab-menu-item {
  width: 25%;
  text-align: center;
  cursor: pointer;
}

.analysis-tab-menu-item span {
  font-weight: bold;
  color: rgb(119, 119, 119);
}

.analysis-tab-menu-item.active hr {
  border: 2px solid red;
}

.analysis-tab-menu-item.active span {
  color: black;
}

.analysis-tab-menu-item hr {
  border: 2px solid darkgray;
}
