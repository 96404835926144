.loans-panel-left {
  width: 60%;
  padding: 20px;
}

.loans-panel-left-resize {
  width: 100%;
  padding: 20px 20px;
}

.loans-panel-right {
  width: 40%;
  padding: 20px;
}

.loans-div-header {
  margin: 0px 0px 40px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
