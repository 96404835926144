/* The snackbar - position it at the bottom and in the middle of the screen */
#terms {
  visibility: visible;
  min-width: 350px; /* Set a default minimum width */
  margin-left: -125px;
  background-color: #ef6969; /* Black background color */
  color: white; /* White text color */
  text-align: center; /* Centered text */
  border-radius: 1em; /* Rounded borders */
  padding: 10px; /* Padding */
  position: fixed; /* Sit on top of the screen */
  z-index: 1; /* Add a z-index if needed */
  left: 40%; /* Center the snackbar */
  bottom: 10px; /* 10px from the bottom */
  border-style: solid;
  display: flex;
}

#terms a {
  color: #80edff;
}

#terms span {
  color: white;
}
