.fnxGreen {
  background-color: #18a71c;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  border: 1px solid #18a71c;
  display: inline-block;
  cursor: pointer;
  color: white;
  font-family: Arial;
  font-size: 15px;
  font-weight: bold;
  padding: 6px 20px;
  text-decoration: none;
}

.fnxGreen:disabled {
  background-color: #dadada;
  border: 0px solid #95a296;
  color: rgb(150, 149, 149);
}

.fnxGreen:hover {
  border: 1px solid var(--fnx-blue);
}

.fnxRed {
  background-color: var(--fnx-red);
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  border: 1px solid var(--fnx-red);
  display: inline-block;
  cursor: pointer;
  color: var(--fnx-white);
  font-family: Arial;
  font-size: 15px;
  font-weight: bold;
  padding: 6px 24px;
  text-decoration: none;
  max-height: 30px;
}

.fnxRed:disabled {
  background-color: #dadada;
  border: 0px solid #95a296;
  color: rgb(150, 149, 149);
}

.fnxGrey {
  background-color: #9c9696;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  border: 1px solid grey;
  display: inline-block;
  cursor: pointer;
  color: rgb(46, 45, 45);
  font-family: Arial;
  font-size: 15px;
  font-weight: bold;
  padding: 6px 24px;
  text-decoration: none;
}

.fnxBlue {
  background-color: var(--fnx-blue);
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  border: 1px solid var(--fnx-blue);
  display: inline-block;
  cursor: pointer;
  color: white;
  font-family: Arial;
  font-size: 15px;
  font-weight: bold;
  padding: 6px 20px;
  text-decoration: none;
}

.small {
  padding: 5px 10px;
  box-shadow: 2px 2px grey;
  max-width: 30px;
  max-height: 30px;
}

.plusButton:hover,
.plusDateButton:hover,
.plusCategoryButton:hover {
  transform: scale(1.1);
  /* Scaling button to 0.98 to its original size */
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
  /* Lowering the shadow */
}

.plusButton {
  background: url("../resources/plus.png");
  background-size: cover;
  width: 35px;
  height: 35px;
  border: 0px;
  cursor: pointer;
  border-radius: "10px";
  margin: 0px 10px;
}

.plusDateButton {
  background: url("../resources/schedule.png");
  background-size: cover;
  width: 35px;
  height: 35px;
  border: 0px;
  cursor: pointer;
  border-radius: "10px";
  margin: 0px 10px;
}

.plusCategoryButton {
  background: url("../resources/analysis.png");
  background-size: cover;
  width: 35px;
  height: 35px;
  border: 1px solid darkgray;
  cursor: pointer;
  border-radius: 10px;
  margin: 0px 10px;
}

.inputError {
  border-color: var(--fnx-red);
  border-width: 3px;
}

.switch {
  position: relative;
  display: inline-block;
  margin: 0 3px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider div {
  position: absolute;
  content: "";
  left: 4px;
  background-color: var(--fnx-white);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: var(--fnx-blue);
}

input:checked + .slider div {
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.linkAsBlueButton {
  background-color: var(--fnx-blue);
  color: white;
  padding: 7px;
  border-radius: 10px;
  font-weight: 700;
  text-decoration: none;
}

.linkAsGreenButton {
  background-color: var(--fnx-green);
  color: white;
  padding: 7px;
  font-weight: 700;
  text-decoration: none;
  min-width: 70px;
  cursor: pointer;
}

.bigButtonGreen {
  background-color: var(--fnx-green);
  color: white;
  padding: 7px;
  text-decoration: none;
  width: 200px;
  font-weight: bold;
  font-size: large;
  border: 2px solid var(--fnx-green);
  margin: 0px;
  text-align: center;
  height: 40px;
}

.bigButtonRed {
  background-color: var(--fnx-red);
  color: white;
  padding: 7px;
  text-decoration: none;
  font-weight: bold;
  font-size: large;
  border: 2px solid var(--fnx-red);
  margin: 0px;
}

.bigButtonGreen:hover {
  border: 2px solid var(--fnx-blue);
}

.bigButtonGreen:disabled {
  background-color: #dadada;
  border: 0px solid #95a296;
  color: rgb(150, 149, 149);
}

.bigButtonBlue {
  background-color: var(--fnx-blue);
  color: white;
  padding: 7px;
  text-decoration: none;
  width: 200px;
  font-weight: bold;
  font-size: large;
  border: 2px solid var(--fnx-blue);
  margin: 0px;
  text-align: center;
  height: 40px;
}
