.loans-dialog-content {
  height: auto;
  margin-top: 2%;
}

.loans-dialog-content-body #shared-users-panel {
  width: 220px;
  height: 100px;
  overflow-y: scroll;
  background-color: white;
  white-space: nowrap;
  overflow-x: scroll;
}

.loans-dialog-content-body #shared-users-panel ul {
  padding: 0px;
  list-style-type: none;
}

.loans-dialog-content-body #shared-users-panel ul li {
  text-align: left;
  margin-bottom: 10px;
  margin-left: 20px;
}

.loans-dialog-content-body #shared-users-panel ul li input[type="checkbox"] {
  margin-right: 20px;
}
