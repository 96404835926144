.profile-table {
  width: 100%;
}

.profile-body {
  margin: 40px 0px;
}

.profile-table tbody tr td {
  text-align: left;
}
