#analysis-page {
  margin: 1% 5%;
}

#analysis-page hr {
  margin-inline: 0px;
  margin-block: 0px;
  border-width: 0px;
}

#analysis-page div.active {
  margin-top: 5px;
  width: 25%;
  height: 3px;
  background-color: var(--fnx-red);
  position: relative;
  transition: transform 1s;
}

#analysis-page div.static {
  height: 5px;
  background-color: var(--fnx-light-grey5);
  border: 1px solid var(--fnx-light-grey5);
  border-radius: 10px;
}

#analysis-page .moveTab0 {
  transform: translateX(0%);
}

#analysis-page .moveTab1 {
  transform: translateX(100%);
}

#analysis-page .moveTab2 {
  transform: translateX(200%);
}

#analysis-page .moveTab3 {
  transform: translateX(300%);
}
