.title.group {
  margin: 40px 0px;
}
.title.group > * {
  margin: 10px;
}

.title.group > input {
  font-size: large;
}

.title.group a {
  font-size: 12px;
  font-weight: normal;
}
.groups-left-panel > * {
  margin-top: 30px;
}

.groups-right-panel a {
  text-decoration: none;
}
